@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy'), url(../assets/fonts/Gilroy-Regular_0.ttf) format('truetype');
}


.line
{
    background-color: var(--accent);
}

.info h3
{
    color: var(--subtle);
}

.info span
{
    color: var(--text-primary);
}

.work h2
{
    color: var(--accent);
}

.work h3
{
    color: var(--subtle);
}

.work p
{
    color: var(--text-primary);
}

.CV
{
    margin-left: 20px;
}

.work
{
    display: flex;
    flex-direction: row;
}

.education
{
    margin-left: 5vh;
}

.intro span
{
    color: var(--accent);
    font-family: gilroy;
    font-size: 40px;
}

.intro
{
    margin-bottom: 13px;
}

.info
{
    margin-top: 5vh;
    margin-bottom: 5vh;
    display: flex;
    flex-direction: row;
}

.mid
{
    font-family: consolas;
}

.developer
{
    margin-left: 4vh;
}

.contact
{
    display: inline-block;
    color: var(--accent);
}

.contact a
{
    color: var(--text-secondary);
}


.MuiFab-circular.MuiFab-root
{
    margin: 5px;
    background-color: var(--text-primary);
    color: var(--background); 
}
.MuiFab-circular.MuiFab-root:hover
{
    background-color: var(--text-primary);
    color: var(--text-secondary); 
}

.contact a 
{
    color: var(--text-primary);
}

.contact a:hover
{
    background-color: bisque;
}

@media (max-width: 700px) {

    .CV
    {
        margin-top: 3vh;
        display: flex;
        justify-content: center;
    }

    .education
    {
        margin-left: 0;
    }

    .info
    {
        display:inline;
    }

    .work
    {
        display: inline;
    }

    .developer
    {
        margin-left: 0;
    }

    .line
    {
        width: 95%;
    }

    .mid
    {
        text-align: center;
    }

}

